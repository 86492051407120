import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import styled from 'styled-components'

export const FormNote = styled.div`
  color: ${COLORS.secondary};
  padding-right: 15px;

  a {
    font-family: ${FONTS.medium};
    text-decoration: underline;
    display: inline-block;
  }
`
