import ListingCard from '@global/ListingCard'
import { Row, Col } from '@styles/global.styled'
import Head from 'next/head'
import React from 'react'

const ListingCardGrid = ({ items }) => {
  return (
    <Row gutter={20}>
      {items.map((item, i) => {
        const data = ListingCard.ApiDataToProps(item)
        return (
          <React.Fragment key={`quick-view-${i}`}>
            {i < 2 && (
              <Head>
                {data.dataSource.image_url && (
                  <link
                    rel='preload'
                    as='image'
                    href={data.dataSource.image_url}
                  />
                )}
              </Head>
            )}
            <Col md={6} key={i} flex>
              <ListingCard quickload={i < 2} {...data} results />
            </Col>
          </React.Fragment>
        )
      })}
    </Row>
  )
}

export default ListingCardGrid
