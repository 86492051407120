import * as S from './FormNote.styled'

const FormNote = () => {
  return (
    <S.FormNote>
      Please note: This form is only to be used for sending genuine email
      enquiries. By completing the form you agree to our{' '}
      <a href='/privacy-statement/' target='_blank'>
        Privacy Statement
      </a>{' '}
      and{' '}
      <a href='/terms-conditions/' target='_blank'>
        Terms & Conditions.
      </a>
    </S.FormNote>
  )
}

export default FormNote
