import { DEVICES } from '@constants/global'
import { COLORS } from '@styles/colors'
import { FONTS } from '@styles/fonts'
import styled, { css } from 'styled-components'

export const ContentWrap = styled.div`
  margin-bottom: 64px;
  padding-bottom: 20px;
  border-bottom: 2px solid ${COLORS.sand};

  @media ${DEVICES.mobile} {
    margin-bottom: 40px;
  }
`

export const Content = styled.div`
  color: ${COLORS.navi};
  position: relative;
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 22px;
  margin-bottom: 32px;

  ${({ isFinishedAnimating, minLines }) =>
    isFinishedAnimating &&
    minLines &&
    css`
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: ${minLines};
    `}

  p,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 16px;
  }

  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
    `}

  ${({ isOpen }) =>
    isOpen &&
    css`
      -webkit-line-clamp: unset;
    `}
`

export const ReadMoreLessButton = styled.span`
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: ${COLORS.secondary};
  font-family: ${FONTS.bold};
  font-size: 14px;
  text-transform: uppercase;

  svg {
    margin-left: 6px;
  }
`
