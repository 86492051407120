import styled, { css } from 'styled-components'
import { COLORS } from '@styles/colors'
import { DEVICES } from '@constants/global'
import { FONTS, textBase, textLG, textSM } from '@styles/fonts'

export const FormWrapper = styled.div`
  ${textBase};
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0;
  top: 0;
  background-color: ${COLORS.white};
  overflow: auto;
  transition: opacity 0.5s ease-in;
  z-index: 10001;
  opacity: 0;

  ${({ active }) =>
    active &&
    css`
      display: block;
      opacity: 1;
    `}

  ${({ isSuccess }) =>
    isSuccess &&
    css`
      background-color: ${COLORS.nature[95]};

      > div {
        background-color: ${COLORS.nature[95]};
      }
    `}
`

export const EnquiryForm = styled.div`
  max-width: 658px;
  padding: 76px 56px 40px;
  margin: 0 auto;

  @media ${DEVICES.tablet} {
    padding: 40px 16px;
  }
`

export const EnquiryFormHeader = styled.div`
  margin-bottom: 32px;
  display: flex;
  align-items: center;

  img {
    width: 72px;
    height: 72px;
    border-radius: 50%;
    margin-right: 20px;
    object-fit: cover;
  }

  @media ${DEVICES.tabletMini} {
    padding-top: 54px;
  }
`

export const EnquiryFormHeaderTitle = styled.span`
  ${textLG};
  letter-spacing: 1.2px;
  margin-bottom: 0;
  display: block;
  text-transform: uppercase;
  font-family: ${FONTS.medium};
  color: ${COLORS.secondary};
`

export const EnquiryFormHeaderDes = styled.span`
  ${textSM};
  font-family: ${FONTS.regular};
  letter-spacing: 1px;
  padding-top: 8px;
  color: ${COLORS.secondary};

  span {
    font-family: ${FONTS.bold};
    text-transform: uppercase;
  }
`

export const EnquiryFormSubHeading = styled.h3`
  color: ${COLORS.secondary};
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
  font-size: 20px;
  line-height: 26px;
  font-weight: 500;

  button {
    font-size: 16px;
    height: 42px;
  }

  @media ${DEVICES.mobile} {
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: flex-start;

    button {
      margin-bottom: 20px;
    }
  }
`

export const EnquiryFormBody = styled.div``

export const EnquiryFormFooter = styled.div`
  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
  }

  label {
    color: ${COLORS.secondary};

    a {
      font-family: ${FONTS.medium};
      color: ${COLORS.secondary};
      text-decoration: underline;

      &:hover {
        color: ${COLORS.primary};
      }
    }

    span {
      ${textBase};
      font-family: ${FONTS.regular};
    }
  }

  > div {
    button {
      white-space: nowrap;
    }
  }

  @media ${DEVICES.mobile} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    > div {
      margin-left: 0;
    }
  }
`
