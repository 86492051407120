import React, { useEffect, useRef, useState } from 'react'
import { Formik } from 'formik'
import SVGIcon from '../../icon/svgIcon'
import {
  DynamicFormData,
  receiveOccasionalBPEmailCheckboxName,
} from './contanst'
import { submitCMSForm, getFormsFields } from '@lib/api-services/formsService'
import { dynamicValidation } from '@lib/helpers/form'
import { toast } from 'react-toastify'
import { logEvent } from '@lib/analyticsService'
import { Router } from '@routes'
import { loadReCaptcha } from 'react-recaptcha-v3'
import { Col, Container, Row } from '@styles/global.styled'
import Field from '@global/FormInput/Field'
import Button from '@global/Button'
import Spinner from '@global/Spinner'
import * as S from './DynamicForm.styled'
import nProgress from 'nprogress'
import { ApiKeys } from '@lib/variables'
import dynamic from 'next/dynamic'
import FormNote from '../FormNote'

const ReCaptcha = dynamic(
  () => import('react-recaptcha-v3').then((mod) => mod.ReCaptcha),
  {
    ssr: false,
  }
)

const DynamicForm = (props) => {
  const { slug, layout, formReference } = props
  const [isShowing, setIsShowing] = useState(false)
  const [formData, setFormData] = useState({})
  const recaptchaRef = useRef()

  const reloadRecaptcha = () => {
    if (window.grecaptcha && recaptchaRef.current) {
      const { sitekey, action, verifyCallback } = recaptchaRef.current.props
      window.grecaptcha.execute(sitekey, { action }).then((token) => {
        if (typeof verifyCallback !== 'undefined') {
          verifyCallback(token)
        }
      })
    }
  }

  const submitForm = async (values, { setSubmitting, resetForm }) => {
    const { success_message, error_message, title } = formData

    nProgress.start()
    setSubmitting(true)
    submitCMSForm(slug, {
      ...values,
      'block-reference': formReference,
      page: Router.router.query.slug,
    }).then((res) => {
      if (res.ok) {
        // GA: log form submission in google analytics
        logEvent('Forms', title, window.location.pathname)
        toast.success(success_message)
        setSubmitting(false)
        resetForm()
        if (formData.redirect) {
          Router.pushRoute(formData.redirect)
        }
      } else {
        toast.error(error_message)
        setSubmitting(false)
        reloadRecaptcha()
      }
      nProgress.done()
    })
  }

  const createSelectOptions = (options) => {
    if (!options || !options.length) return

    let selectOptions = []

    options.map((opt) => {
      selectOptions.push({
        value: opt[0],
        label: opt[1],
      })
    })

    return selectOptions
  }

  useEffect(() => {
    getFormsFields(slug).then((formFields) => {
      let formData = DynamicFormData[slug] || DynamicFormData.default
      formData.leftFields = []
      formData.rightFields = []
      formData.formSubmit = formFields.submit
      formData.validateSchema = {}
      formData.title = formFields.title
      formData.success_message = formFields.success_message
      formData.error_message = formFields.error_message
      formData.initialValues = {
        recaptcha: '',
      }

      if (formFields) {
        formData.redirect = formFields.post_redirect_page || null
        formFields.form?.every((field) => {
          delete field.value
          if (field.type === 'select')
            field.options = createSelectOptions(field.choices)

          if (formData.leftFieldsName.find((s) => field.name == s)) {
            formData.leftFields.push(field)
          }
          if (formData.rightFieldsName.find((s) => field.name == s)) {
            formData.rightFields.push(field)
          }
          return true
        })
      }

      formData.validateSchema = dynamicValidation(formFields.form)
      formData.leftFields.map((field) => {
        const defaultVal =
          field.options && field.options[0] ? field.options[0].value : ''
        formData.initialValues[field.name] = defaultVal
      })

      setFormData({ ...formData })
      setIsShowing(true)
    })
  }, [slug])

  const {
    title,
    leftFields,
    rightFields,
    formSubmit,
    socialLinks,
    initialValues,
    validateSchema,
  } = formData

  useEffect(() => {
    if (!window.RECAPTCHA_INITIALIZED) {
      loadReCaptcha(ApiKeys.RECAPTCHA_KEY)
      window.RECAPTCHA_INITIALIZED = true
    }
  }, [])

  return (
    isShowing && (
      <Formik
        validationSchema={validateSchema}
        onSubmit={(values, { setSubmitting, resetForm }) => {
          submitForm(values, { setSubmitting, resetForm })
        }}
        initialValues={{
          ...initialValues,
          [receiveOccasionalBPEmailCheckboxName]: true,
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          isSubmitting,
          getFieldProps,
          errors,
          values,
          touched,
        }) => (
          <S.DynamicForm>
            <Container>
              <form noValidate onSubmit={handleSubmit}>
                <ReCaptcha
                  sitekey={ApiKeys.RECAPTCHA_KEY}
                  action='referral'
                  verifyCallback={async (token) => {
                    if (values.recaptcha !== token) {
                      setFieldValue('recaptcha', token, false)
                    }
                  }}
                  ref={recaptchaRef}
                />
                <S.DynamicFormHeader>
                  <h3>{title}</h3>
                  {socialLinks?.length > 0 && (
                    <ul>
                      {socialLinks.map((link, i) => (
                        <li key={`socialLink-${i}`}>
                          <a href={link.url}>
                            <SVGIcon name={link.name} />
                          </a>
                        </li>
                      ))}
                    </ul>
                  )}
                </S.DynamicFormHeader>
                <Row gutter={16}>
                  <Col md={layout === 'single_column' ? 12 : 6}>
                    {leftFields?.map(
                      (field, index) =>
                        field && (
                          <Field
                            key={`leftFields-${index}`}
                            {...field}
                            {...getFieldProps(field.name)}
                            placeholder={field.help_text}
                            error={touched[field.name] && errors[field.name]}
                          />
                        )
                    )}
                  </Col>
                  <Col md={layout === 'single_column' ? 12 : 6}>
                    {rightFields?.map(
                      (field, index) =>
                        field && (
                          <Field
                            key={`rightFields-${index}`}
                            {...field}
                            {...getFieldProps(field.name)}
                            placeholder={field.help_text}
                            error={touched[field.name] && errors[field.name]}
                          />
                        )
                    )}
                  </Col>
                </Row>
                <S.Note>
                  <div>
                    <FormNote />

                    {formSubmit ? (
                      <Button
                        color='primary'
                        type='submit'
                        disabled={isSubmitting}
                      >
                        {formSubmit.label ?? 'Submit'}
                      </Button>
                    ) : null}
                  </div>
                  <Field
                    type='checkbox'
                    {...getFieldProps(receiveOccasionalBPEmailCheckboxName)}
                    checked={values[receiveOccasionalBPEmailCheckboxName]}
                    onChange={(e) => {
                      setFieldValue(
                        receiveOccasionalBPEmailCheckboxName,
                        e.target.checked
                      )
                    }}
                    error={
                      touched?.[receiveOccasionalBPEmailCheckboxName] &&
                      errors?.[receiveOccasionalBPEmailCheckboxName]
                    }
                    label={
                      <span>
                        I{"'"}d like to receive occasional Barry Plant Group
                        email updates and insights
                      </span>
                    }
                  />
                </S.Note>
              </form>
            </Container>
            {isSubmitting && <Spinner />}
          </S.DynamicForm>
        )}
      </Formik>
    )
  )
}

DynamicForm.defaultProps = {}

// eslint-disable-next-line react/display-name
DynamicForm.ApiBlockToProps = (data) => {
  if (!data || !data.value) {
    return null
  }

  return {
    slug: data.value.slug,
    formReference: data.value.form_reference,
    layout: data.value.layout,
  }
}

export default DynamicForm
